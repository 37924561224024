import React, { useState, useRef, useEffect } from "react";
import Modal from "./Modal"; // 导入模态框组件
import "./Services.css"; // 确保路径正确
import { useContext } from "react";
import { AppContext } from "./AppContext";

const Services = () => {
  const [inputText, setInputText] = useState("");
  const [responses, setResponses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // 控制模态框的状态
  const [modalContent, setModalContent] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showInitialScreen, setShowInitialScreen] = useState(true);
  const messagesEndRef = useRef(null);
  const { testResult, setTestResult } = useContext(AppContext);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [responses]);

  useEffect(() => {
    if (testResult) {
      // 如果有测试结果，自动开始对话
      handleTestResultChat();
    }
  }, []); // 只在组件挂载时运行一次

  const addElement = (term, desc, isClickable = false) => {
    const newElement = (
      <div
        key={responses.length}
        className={`response-item ${
          term === "You" ? "user-message" : "chatbot-message"
        }`}
      >
        {term === "ChatGPT" && !isClickable ? (
          <img
            src={require("./assets/Images/chatgpt.png")}
            width="40"
            height="40"
            alt={term}
            className="avatar"
          />
        ) : null}

        {isClickable ? (
          <span
            onClick={() => handleQuestionClick(desc)}
            style={{
              color: "7f9c85",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {desc}
          </span>
        ) : (
          <dd
            className={term === "ChatGPT" ? "chatbot-message" : "user-message"}
          >
            {desc}
          </dd>
        )}

        {term === "You" && !isClickable ? (
          <img
            src={require("./assets/Images/you.png")}
            width="40"
            height="40"
            alt={term}
            className="avatar"
          />
        ) : null}
      </div>
    );
    setResponses((prev) => [...prev, newElement]);
  };
  const handleBackClick = () => {
    setShowInitialScreen(true); // 返回初始屏幕
    setResponses([]);
  };

  const handleSubmit = async () => {
    if (inputText) {
      setIsTyping(true);
      setShowInitialScreen(false);
      addElement("You", inputText);

      const response = await chatGPT(inputText);
      addElement("ChatGPT", response.content);

      // 添加生成的问题，但确保不重复
      const existingQuestions = responses.filter(
        (resp) => resp.props.children === inputText
      ).length;

      if (existingQuestions === 0) {
        response.followUpQuestions.forEach((followUpQuestion) => {
          addElement("ChatGPT", followUpQuestion, true); // 传递一个标志，表示这是一个可点击的问题
        });
      }

      setInputText("");

      setTimeout(() => {
        setIsTyping(false);
      }, 1500);
    }
  };

  const chatGPT = async (message) => {
    const apiKey =
      "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"; // 替换为你的实际 API 密钥
    const endpoint = "https://api.openai.com/v1/chat/completions";

    const data = {
      messages: [
        {
          role: "system",
          content: `
                    You are Vidia, a highly intelligent skincare assistant designed to provide personalized and expert-level guidance on skincare routines, product recommendations, dietary advice, and treatments. 
                    Always respond with clarity, empathy, and backed by credible sources. Your primary goals are to educate users, build trust, and help them achieve their skincare goals. Be professional, yet approachable. Maintain a friendly and understanding tone, especially for sensitive topics.
                    After every answer, generate 2-3 related follow-up questions that encourage deeper exploration of the topic for users to ask more.
                   
                `,
        },
        { role: "user", content: message },
      ],
      model: "gpt-3.5-turbo",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(endpoint, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const result = await response.json();
      const content = result.choices[0].message.content; // 返回 ChatGPT 的响应

      // 提取生成的问题
      const followUpQuestions = extractQuestions(content);
      return { content, followUpQuestions }; // 返回内容和问题
    } catch (error) {
      console.error("Error:", error);
      return "对不起，发生了错误。"; // 返回错误信息
    }
  };

  // 修改提取问题的函数，只提取用户可以提问的问题
  const extractQuestions = (content) => {
    // 匹配以数字开头，后面跟着一个问题的句子
    const regex = /^\d+\.\s(.*?\?)$/gm; // 只匹配以数字开头并以问号结尾的句子
    const allQuestions = content.match(regex) || []; // 返回匹配到的问题

    // 过滤出用户可以提问的问题，并移除数字前缀
    const userQuestions = allQuestions.map((question) => {
      return question.replace(/^\d+\.\s/, ""); // 移除数字和点
    });

    return userQuestions; // 返回过滤后的问题
  };

  const openModal = () => {
    setModalContent("这里是服务的详细信息..."); // 可以自定义内容
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // 按下回车并且不按住shift
      e.preventDefault(); // 阻止换行
      handleSubmit(); // 触发提交消息
    }
  };

  const handleQuestionClick = async (question) => {
    setIsTyping(true);
    setShowInitialScreen(false);
    addElement("You", question); // 添加用户提问

    const response = await chatGPT(question); // 获取 ChatGPT 的响应
    addElement("ChatGPT", response.content); // 添加 ChatGPT 的响应

    // 添加生成的问题，但确保不重复
    const existingQuestions = responses.filter(
      (resp) => resp.props.children === question
    ).length;

    if (existingQuestions === 0) {
      response.followUpQuestions.forEach((followUpQuestion) => {
        addElement("ChatGPT", followUpQuestion, true); // 传递一个标志，表示这是一个可点击的问题
      });
    }

    setIsTyping(false);
  };

  const handleTestResultChat = async () => {
    if (!testResult) return; // 如果没有测试结果，直接返回

    setShowInitialScreen(false);
    addElement(
      "You",
      "I just finished the skin test. Can you provide me with some advice based on the results?just finished the skin test. Can you provide me with some advice based on the results?"
    );

    const response =
      await chatGPT(`Based on the skin test results:the skin test results: 
      Summary：${testResult.summary}
      Skin Type：${testResult.skinType}
      
      Please provide personalized skincare advice and product recommendations.`);

    addElement("ChatGPT", response.content);

    // 清除测试结果，避免重复处理
    setTestResult(null);
  };

  return (
    <div>
      <h1>Vidia</h1>
      {showInitialScreen ? (
        <>
          <p>
            Hello! I'm Vidia! An AI digital assistant here to provide answers
            for your skin care needs. How can I assist you today?
          </p>
          <div className="question-buttons">
            <button
              onClick={() =>
                handleQuestionClick("When should I start using retinol?")
              }
            >
              When should I start using retinol?
            </button>
            <button
              onClick={() =>
                handleQuestionClick("What should I use for whiteheads?")
              }
            >
              What should I use for whiteheads?
            </button>
            <button
              onClick={() =>
                handleQuestionClick("How often should you be using a toner?")
              }
            >
              How often should you be using a toner?
            </button>
            <button
              onClick={() =>
                handleQuestionClick("What's a good cleanser for oil control?")
              }
            >
              What's a good cleanser for oil control?
            </button>
          </div>
        </>
      ) : (
        <>
          <button
            onClick={handleBackClick}
            className="back-button"
            aria-label="返回"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="24"
              height="24"
            >
              <path
                d="M 25 2 C 12.308594 2 2 12.308594 2 25 C 2 37.691406 12.308594 48 25 48 C 37.691406 48 48 37.691406 48 25 C 48 12.308594 37.691406 2 25 2 Z M 25 4 C 36.609375 4 46 13.390625 46 25 C 46 36.609375 36.609375 46 25 46 C 13.390625 46 4 36.609375 4 25 C 4 13.390625 13.390625 4 25 4 Z M 30.292969 14.292969 L 18.585938 25 L 30.292969 35.707031 L 31.707031 34.292969 L 21.414063 25 L 31.707031 15.707031 Z"
                fill="#7f9c85"
              />
            </svg>
          </button>
          <p>
            Note: I cannot diagnose or treat medical conditions. Please consult
            a licensed professional for appropriate care
          </p>
        </>
      )}
      <div id="output-text">
        <dl id="theList">{responses}</dl> {/* 渲染消息 */}
        {isTyping && (
          <div className="flex justify-start mb-4">
            <div className="bg-gray-300 rounded-lg p-2 max-w-xs">
              <div className="typing-animation">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container">
        <textarea
          id="input-text"
          placeholder="ask me anything..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown}
        ></textarea>
        <button
          id="submit-button"
          onClick={handleSubmit}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-2xl"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
      />
    </div>
  );
};

export default Services;
