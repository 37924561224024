import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "./AppContext";
// 导入所有皮肤类型图片
import normalSkin from "./assets/Images/skintypes/normal.png";
import drySkin from "./assets/Images/skintypes/dry.png";
import oilySkin from "./assets/Images/skintypes/oily.png";
import combinationSkin from "./assets/Images/skintypes/combination.png";
import sensitiveSkin from "./assets/Images/skintypes/sensitive.png";
import agingMatureSkin from "./assets/Images/skintypes/mature.png";
import "./SkinTest.css"; // 确保这行存在并且路径正确

const questions = [
  {
    id: 1,
    question: "How does your skin typically feel throughout the day?",
    options: [
      "Balanced and comfortable",
      "Dry and tight",
      "Oily in some areas, normal in others",
    ],
  },
  {
    id: 2,
    question: "How are your pores?",
    options: ["Very noticeable", "Somewhat noticeable", "Not very noticeable"],
  },
  {
    id: 3,
    question: "Is your skin prone to breakouts?",
    options: ["Rarely", "Occasionally", "Often"],
  },
  {
    id: 4,
    question: "Does your skin remain shiny long after washing?",
    options: ["Most areas", "T-zone only", "Rarely"],
  },
  {
    id: 5,
    question: "Does your skin feel tight and dry?",
    options: ["Rarely", "Sometimes on cheeks", "Often"],
  },
  {
    id: 6,
    question: "Do you have fine lines and wrinkles?",
    options: ["None at all", "Very few/around eyes", "Yes"],
  },
  {
    id: 7,
    question: "How often do you use skincare products?",
    options: ["Never used", "2-3 times per week", "Daily"],
  },
  {
    id: 8,
    question: "Does your skin tone appear uneven with dark spots?",
    options: ["Rarely", "Sometimes", "Often"],
  },
  {
    id: 9,
    question: "Is your skin unable to tolerate some skincare products?",
    options: ["No", "Sometimes", "Often"],
  },
  {
    id: 10,
    question: "Does your skin produce excess oil?",
    options: ["No", "A little", "Yes"],
  },
  {
    id: 11,
    question: "Do you have fine lines around your eyes?",
    options: ["No", "Yes", ""],
  },
  {
    id: 12,
    question: "How does your skin react to seasonal changes?",
    options: [
      "Stays the same",
      "Gets drier in cold weather",
      "Gets oilier in summer",
    ],
  },
];

const SkinTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const { updateTestResult } = useContext(AppContext);
  const navigate = useNavigate();
  const [skinTypeImage, setSkinTypeImage] = useState(null);

  const skinTypeImages = {
    normal: normalSkin,
    dry: drySkin,
    oily: oilySkin,
    combination: combinationSkin,
    sensitive: sensitiveSkin,
    agingMature: agingMatureSkin,
  };

  const handleAnswer = (optionIndex) => {
    const newAnswers = {
      ...answers,
      [currentQuestion]: optionIndex,
    };
    setAnswers(newAnswers);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // 这是最后一个问题
      console.log("Last question answered, preparing to finish test");
      handleFinishTest(newAnswers);
    }
  };

  const handleFinishTest = (finalAnswers) => {
    console.log("Test completed, preparing results");
    setShowSummary(true);
    const summary = getTestSummary(finalAnswers);
    console.log("Test summary:", summary);

    const skinType = analyzeSkinType(
      Object.values(finalAnswers).map(
        (optionIndex, questionIndex) =>
          questions[questionIndex].options[optionIndex]
      )
    );

    // 保存测结果到状态
    setTestResult({
      summary: summary,
      skinType: skinType,
    });

    // 设置对应的皮肤类型图片
    setSkinTypeImage(skinTypeImages[skinType]);
  };

  const handleSendResults = () => {
    updateTestResult({
      skinType: testResult.skinType,
      description: getSkinTypeDescription(testResult.skinType)
    });
    navigate("/services");
  };

  const getTestSummary = (finalAnswers) => {
    console.log("Generating test summary");
    return questions
      .map((q, index) => {
        const answerIndex = finalAnswers[index];
        const selectedOption = q.options[answerIndex];
        return `Question ${index + 1}: ${
          q.question
        }\nAnswer: ${selectedOption}`;
      })
      .join("\n\n");
  };

  const analyzeSkinType = (answers) => {
    const skinTypeScores = {
      normal: 0,
      sensitive: 0,
      combination: 0,
      dry: 0,
      oily: 0,
      agingMature: 0,
    };

    answers.forEach((answer, index) => {
      switch (index) {
        case 0: // How does your skin typically feel throughout the day?
          if (answer === "Balanced and comfortable") skinTypeScores.normal++;
          if (answer === "Dry and tight") skinTypeScores.dry++;
          if (answer === "Oily in some areas, normal in others")
            skinTypeScores.combination++;
          break;
        case 1: // How are your pores?
          if (answer === "Very noticeable") skinTypeScores.oily++;
          if (answer === "Somewhat noticeable") skinTypeScores.combination++;
          if (answer === "Not very noticeable") skinTypeScores.normal++;
          break;
        case 2: // Is your skin prone to breakouts?
          if (answer === "Often") skinTypeScores.oily++;
          if (answer === "Occasionally") skinTypeScores.combination++;
          if (answer === "Rarely") skinTypeScores.normal++;
          break;
        case 3: // Does your skin remain shiny long after washing?
          if (answer === "Most areas") skinTypeScores.oily++;
          if (answer === "T-zone only") skinTypeScores.combination++;
          if (answer === "Rarely") skinTypeScores.normal++;
          break;
        case 4: // Does your skin feel tight and dry?
          if (answer === "Often") skinTypeScores.dry++;
          if (answer === "Sometimes on cheeks") skinTypeScores.combination++;
          if (answer === "Rarely") skinTypeScores.normal++;
          break;
        case 5: // Do you have fine lines and wrinkles?
          if (answer === "Yes") skinTypeScores.agingMature++;
          break;
        case 6: // How often do you use skincare products?
          // This question doesn't directly indicate a skin type
          break;
        case 7: // Does your skin tone appear uneven with dark spots?
          if (answer === "Often") skinTypeScores.agingMature++;
          break;
        case 8: // Is your skin unable to tolerate some skincare products?
          if (answer === "Often") skinTypeScores.sensitive++;
          if (answer === "Sometimes") skinTypeScores.sensitive += 0.5;
          break;
        case 9: // Does your skin produce excess oil?
          if (answer === "Yes") skinTypeScores.oily++;
          if (answer === "A little") skinTypeScores.combination++;
          break;
        case 10: // Do you have fine lines around your eyes?
          if (answer === "Yes") skinTypeScores.agingMature++;
          break;
        case 11: // How does your skin react to seasonal changes?
          if (answer === "Gets drier in cold weather") skinTypeScores.dry++;
          if (answer === "Gets oilier in summer") skinTypeScores.oily++;
          break;
      }
    });

    // 找出得分最高的皮肤类型
    const highestScore = Math.max(...Object.values(skinTypeScores));
    const skinTypes = Object.keys(skinTypeScores).filter(
      (type) => skinTypeScores[type] === highestScore
    );

    // 如果有多个最高分，优先选择 combination
    if (skinTypes.includes("combination")) {
      return "combination";
    }

    // 否则返回第一个最高分的皮肤类型
    return skinTypes[0];
  };

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  const getSkinTypeDescription = (skinType) => {
    const descriptions = {
      normal: [
        "Neither too dry, nor too oily",
        "Main objective is to cleanse to remove dead cells from the epidermis and impurities from the hair follicles",
        "Moisture and protection from the environment will maintain this skin type and help keep it healthy and radiant",
      ],
      dry: [
        "Feels tight and may look flaky",
        "Needs extra hydration and protection",
        "Focus on gentle cleansing and rich moisturizers",
      ],
      oily: [
        "Excess sebum production leading to shine",
        "Prone to acne and enlarged pores",
        "Requires oil control and non-comedogenic products",
      ],
      combination: [
        "Oily T-zone with normal to dry cheeks",
        "Needs balanced care for different areas",
        "Use targeted treatments for each skin concern",
      ],
      sensitive: [
        "Easily irritated and prone to redness",
        "Requires gentle, fragrance-free products",
        "Focus on soothing and strengthening the skin barrier",
      ],
      agingMature: [
        "Shows signs of aging like fine lines and wrinkles",
        "Needs extra hydration and anti-aging ingredients",
        "Focus on collagen production and skin firmness",
      ],
    };
    return descriptions[skinType] || [];
  };

  return (
    <div className="quiz-container">
      {!showSummary ? (
        <div>
          <h2>
            Question {currentQuestion + 1} / {questions.length}
          </h2>
          <p className="question">{questions[currentQuestion].question}</p>
          <div className="options-container">
            {questions[currentQuestion].options.map(
              (option, index) =>
                option && (
                  <button
                    key={index}
                    onClick={() => handleAnswer(index)}
                    className={`option-button ${
                      answers[currentQuestion] === index ? "selected" : ""
                    }`}
                  >
                    {option}
                  </button>
                )
            )}
          </div>
          <div className="progress-container">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="summary-container">
          <h2>Your Skin Type: {testResult.skinType}</h2>
          {skinTypeImage && (
            <img
              src={skinTypeImage}
              alt={`${testResult.skinType} skin type`}
              style={{ maxWidth: "300px", marginBottom: "20px" }}
            />
          )}
          <div className="skin-type-description">
            {getSkinTypeDescription(testResult.skinType).map((desc, index) => (
              <div
                key={index}
                className="description-item"
              >
                <div className="number-circle">
                  {String(index + 1).padStart(2, "0")}
                </div>
                <p>{desc}</p>
              </div>
            ))}
          </div>
          <button onClick={handleSendResults}>Send Results</button>
        </div>
      )}
    </div>
  );
};

export default SkinTest;
