import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./ImageSlider.css"; // Optional: For custom styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  console.log("ImageSlider component loaded"); // 调试信息，检查组件是否加载
  const [images, setImages] = useState([]); // 初始化图片数组为空
  const [isLoading, setIsLoading] = useState(true); // 加载状态

  // 设置slider的配置
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // 模拟异步加载图片
  useEffect(() => {
    const loadImages = async () => {
      // 模拟异步加载图片
      const fetchedImages = [
        require("./assets/Images/Image1.png"),
        require("./assets/Images/Image2.png"),
        require("./assets/Images/Image3.png"),
      ];

      setImages(fetchedImages); // 设置图片数组
      setIsLoading(false); // 图片加载完成，更新加载状态
    };

    loadImages();
  }, []); // 确保仅在组件挂载时执行

  // 当图片加载时显示loading，加载完后显示slider
  return (
    <div className="slider-container">
      {isLoading ? (
        <p>Loading images...</p> // 显示加载状态
      ) : (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="slide-image"
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ImageSlider;
