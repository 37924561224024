import React from "react";
import "./Modal.css"; // 确保创建了相应的 CSS 文件

const Modal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null; // 如果未打开，不渲染模态框

  return (
    <div
      className="modal-overlay"
      onClick={onClose}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="close-button"
          onClick={onClose}
        >
          关闭
        </button>
        <h2>服务</h2>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Modal;
