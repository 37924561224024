// src/Home.js
import React, { useEffect } from "react";
import ImageSlider from "./ImageSlider"; // 确保路径正确

const Home = () => {
  return (
    <div>
      <h1>Welcome to Vidia1.0</h1>
      <ImageSlider /> {/* 正确渲染ImageSlider */}
    </div>
  );
};

export default Home;
