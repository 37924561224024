// src/Navbar.js
import React from "react";
import { Link } from "react-router-dom"; // 导入 Link 组件
import "./Navbar.css"; // 确保路径正确
import { FaHome, FaInfoCircle, FaComments, FaEnvelope } from "react-icons/fa";
import { IoIosHome } from "react-icons/io";
import { CiChat2 } from "react-icons/ci";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="nav-items">
        <Link
          to="/"
          className="nav-item"
        >
          <IoIosHome />
        </Link>
        <Link
          to="/services"
          className="nav-item"
        >
          <CiChat2 />
        </Link>{" "}
        <Link
          to="/Skintest"
          className="nav-item"
        >
          SkinTest
        </Link>
        {/* <Link
          to="/MenstrualCycleTracker"
          className="nav-item"
        >
          {" "}
          Period Tracker
        </Link> */}
        <Link
          to="/about"
          className="nav-item"
        >
          About Us
        </Link>
        {/* 添加服务链接 */}
      </div>
    </nav>
  );
};

export default Navbar;
