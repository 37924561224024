import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [testResult, setTestResult] = useState(null);

  const updateTestResult = (result) => {
    setTestResult(result);
  };

  const resetTestResult = () => {
    setTestResult(null);
  };

  return (
    <AppContext.Provider
      value={{ testResult, updateTestResult, resetTestResult }}
    >
      {children}
    </AppContext.Provider>
  );
};
