import React from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "./ImageSlider"; // 确保路径正确
import Navbar from "./Navbar"; // 确保路径正确
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // 更新导入
import About from "./About"; // 确保路径正确
import Services from "./Services";
import Home from "./Home"; // 确保路径正确
import SkinTest from "./SkinTest.js"; // 确保路径正确
import MenstrualCycleTracker from "./MenstrualCycleTracker.jsx";
import { AppProvider } from "./AppContext";

const App = () => {
  return (
    <AppProvider>
      <Router>
        {" "}
        {/* 包裹整个应用以使用路由 */}
        <div className="container">
          <main className="main-content">
            <Routes>
              <Route
                path="/"
                element={<Home />}
              />
              <Route
                path="/about"
                element={<About />}
              />
              <Route
                path="/services"
                element={<Services />}
              />
              <Route
                path="/SkinTest"
                element={<SkinTest />}
              />
              <Route
                path="/MenstrualCycleTracker"
                element={<MenstrualCycleTracker />}
              />
            </Routes>
          </main>
          <Navbar /> {/* 固定在底部的导航栏 */}
        </div>
      </Router>
    </AppProvider>
  );
};

export default App;
