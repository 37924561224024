// src/About.js
import React from "react";

const About = () => {
  return (
    <div>
      <h1>About US</h1>
      <p>Personalized acne advice, skincare tips, and confidence boost</p>
    </div>
  );
};

export default About;
